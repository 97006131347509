
import Toolbar from '../components/Toolbar'
import Footer from '../components/Footer'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Toolbar,
    Footer
  },
  head: () => ({
    __dangerouslyDisableSanitizers: ['script'],
    script: [
      {
        innerHTML: `{
            "@context": "http://schema.org",
            "@type" : "Organization",
            "name" : "BigFive",
            "url" : "https://bigfive-test.com",
            "logo": "https://bigfive-test.com/icon.png"
          }`,
        type: 'application/ld+json'
      }
    ]
  }),
  computed: mapState(['snackbar']),
  created () {
    const { dir } = this.$i18n.locales.find(i => i.code === this.$i18n.locale)
    this.$vuetify.rtl = dir === 'rtl'
  }
}
