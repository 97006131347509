export const BarChart = () => import('../../components/BarChart.vue' /* webpackChunkName: "components/bar-chart" */).then(c => wrapFunctional(c.default || c))
export const BarChartCompare = () => import('../../components/BarChartCompare.vue' /* webpackChunkName: "components/bar-chart-compare" */).then(c => wrapFunctional(c.default || c))
export const CookieBox = () => import('../../components/CookieBox.vue' /* webpackChunkName: "components/cookie-box" */).then(c => wrapFunctional(c.default || c))
export const Domain = () => import('../../components/Domain.vue' /* webpackChunkName: "components/domain" */).then(c => wrapFunctional(c.default || c))
export const DomainCompare = () => import('../../components/DomainCompare.vue' /* webpackChunkName: "components/domain-compare" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../components/Error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Highlightcom = () => import('../../components/Highlightcom.vue' /* webpackChunkName: "components/highlightcom" */).then(c => wrapFunctional(c.default || c))
export const Highlights = () => import('../../components/Highlights.vue' /* webpackChunkName: "components/highlights" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const ShareLinks = () => import('../../components/ShareLinks.vue' /* webpackChunkName: "components/share-links" */).then(c => wrapFunctional(c.default || c))
export const TestLanguageSwitcher = () => import('../../components/TestLanguageSwitcher.vue' /* webpackChunkName: "components/test-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const Toolbar = () => import('../../components/Toolbar.vue' /* webpackChunkName: "components/toolbar" */).then(c => wrapFunctional(c.default || c))
export const Wrapper = () => import('../../components/Wrapper.vue' /* webpackChunkName: "components/wrapper" */).then(c => wrapFunctional(c.default || c))
export const FormAge = () => import('../../components/form/Age.vue' /* webpackChunkName: "components/form-age" */).then(c => wrapFunctional(c.default || c))
export const FormConfirm = () => import('../../components/form/Confirm.vue' /* webpackChunkName: "components/form-confirm" */).then(c => wrapFunctional(c.default || c))
export const FormGender = () => import('../../components/form/Gender.vue' /* webpackChunkName: "components/form-gender" */).then(c => wrapFunctional(c.default || c))
export const FormInfo = () => import('../../components/form/Info.vue' /* webpackChunkName: "components/form-info" */).then(c => wrapFunctional(c.default || c))
export const FormLanguage = () => import('../../components/form/Language.vue' /* webpackChunkName: "components/form-language" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/form/index.js' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureList = () => import('../../components/product/FeatureList.vue' /* webpackChunkName: "components/product-feature-list" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductTitle = () => import('../../components/product/ProductTitle.vue' /* webpackChunkName: "components/product-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
