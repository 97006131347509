export const vueI18n = {}
export const vueI18nLoader = false
export const locales = [{"name":"Norsk","code":"no","iso":"nb-NO","file":"nb-NO.js","dir":"ltr"},{"name":"Dansk","code":"da","iso":"da","file":"da.js","dir":"ltr"},{"name":"Svenska","code":"sv","iso":"sv","file":"sv.js","dir":"ltr"},{"name":"Arabic","code":"ar","iso":"ar","file":"ar.js","dir":"rtl"},{"name":"Deutsch","code":"de","iso":"de","file":"de.js","dir":"ltr"},{"name":"Finnish","code":"fi","iso":"fi","file":"fi.js","dir":"ltr"},{"name":"Spanish","code":"es","iso":"es","file":"es.js","dir":"ltr"},{"name":"English","code":"en","iso":"en-US","file":"en-US.js","dir":"ltr"},{"name":"Hind","code":"hi","iso":"hi","file":"hi.js","dir":"ltr"},{"name":"French","code":"fr","iso":"fr","file":"fr.js","dir":"ltr"},{"name":"Russian","code":"ru","iso":"ru","file":"ru.js","dir":"ltr"},{"name":"Chinese","code":"zh-Hans","iso":"zh-Hans","file":"zh-Hans.js","dir":"ltr"},{"name":"Bahasa Indonesia","code":"id","iso":"id","file":"id.js","dir":"ltr"},{"name":"Ukrainian","code":"uk","iso":"uk","file":"uk.js","dir":"ltr"},{"name":"Japanese","code":"ja","iso":"ja","file":"ja.js","dir":"ltr"},{"name":"Polish","code":"pl","iso":"pl","file":"pl.js","dir":"ltr"},{"name":"Brazilian Portuguese","code":"pt","iso":"pt-br","file":"pt-BR.js","dir":"ltr"},{"name":"Italian","code":"it","iso":"it","file":"it.js","dir":"ltr"},{"name":"Romanian","code":"is","iso":"is","file":"is.js","dir":"ltr"},{"name":"Thai","code":"th","iso":"th","file":"th.js","dir":"ltr"}]
export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = 'translations/'
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"alwaysRedirect":false,"fallbackLocale":"en","onlyOnNoPrefix":false,"onlyOnRoot":true}
export const differentDomains = false
export const seo = true
export const baseUrl = 'https://test.recruto.ro'
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DIR_KEY = 'dir'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["no","da","sv","ar","de","fi","es","en","hi","fr","ru","zh-Hans","id","uk","ja","pl","pt","it","is","th"]
export const trailingSlash = undefined
