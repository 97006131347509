
import { mdiMenu } from '@mdi/js'

export default {
  components: {
    LanguageSwitcher: () => import('./LanguageSwitcher')
  },
  data: function () {
    const icons = { mdiMenu }
    return {
      drawer: false,
      logoSrc: require('@/assets/logo.png'),
      icons
    }
  }
}

