import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ef1ab72a&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=ef1ab72a&prod&lang=css&"
import style1 from "./default.vue?vue&type=style&index=1&id=ef1ab72a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef1ab72a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toolbar: require('/vercel/path0/components/Toolbar.vue').default,Footer: require('/vercel/path0/components/Footer.vue').default})
