import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d786f232 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _2e2882b7 = () => interopDefault(import('../pages/agreeableness.vue' /* webpackChunkName: "pages/agreeableness" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5cb47fd3 = () => interopDefault(import('../pages/articles.vue' /* webpackChunkName: "pages/articles" */))
const _a9ba93b2 = () => interopDefault(import('../pages/big_five.vue' /* webpackChunkName: "pages/big_five" */))
const _8644c646 = () => interopDefault(import('../pages/candidate.vue' /* webpackChunkName: "pages/candidate" */))
const _a4d1909c = () => interopDefault(import('../pages/companii.vue' /* webpackChunkName: "pages/companii" */))
const _58f5df57 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _25c5e702 = () => interopDefault(import('../pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _276f6029 = () => interopDefault(import('../pages/conscientiousness.vue' /* webpackChunkName: "pages/conscientiousness" */))
const _79b33d7a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _119a8c69 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _63a157de = () => interopDefault(import('../pages/extraversion.vue' /* webpackChunkName: "pages/extraversion" */))
const _5fa2ea70 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _c5921996 = () => interopDefault(import('../pages/gdpr.vue' /* webpackChunkName: "pages/gdpr" */))
const _17f60daa = () => interopDefault(import('../pages/neuroticism.vue' /* webpackChunkName: "pages/neuroticism" */))
const _74168d26 = () => interopDefault(import('../pages/openness_to_experience.vue' /* webpackChunkName: "pages/openness_to_experience" */))
const _5e416e3c = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _9ea4d90c = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _62f66176 = () => interopDefault(import('../pages/result/index.vue' /* webpackChunkName: "pages/result/index" */))
const _5df21fe1 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _1dc72ea8 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _58809db8 = () => interopDefault(import('../pages/thank_you.vue' /* webpackChunkName: "pages/thank_you" */))
const _99e35aaa = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _ca0c94b6 = () => interopDefault(import('../pages/parteneri/ang.vue' /* webpackChunkName: "pages/parteneri/ang" */))
const _0331dbd2 = () => interopDefault(import('../pages/parteneri/hpdi.vue' /* webpackChunkName: "pages/parteneri/hpdi" */))
const _1f747c2c = () => interopDefault(import('../pages/compare/_id.vue' /* webpackChunkName: "pages/compare/_id" */))
const _52eda964 = () => interopDefault(import('../pages/parteneri/_partner.vue' /* webpackChunkName: "pages/parteneri/_partner" */))
const _687a495e = () => interopDefault(import('../pages/result/_id.vue' /* webpackChunkName: "pages/result/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d786f232,
    name: "about___en"
  }, {
    path: "/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___en"
  }, {
    path: "/ar",
    component: _98173ca8,
    name: "index___ar"
  }, {
    path: "/articles",
    component: _5cb47fd3,
    name: "articles___en"
  }, {
    path: "/big_five",
    component: _a9ba93b2,
    name: "big_five___en"
  }, {
    path: "/candidate",
    component: _8644c646,
    name: "candidate___en"
  }, {
    path: "/companii",
    component: _a4d1909c,
    name: "companii___en"
  }, {
    path: "/company",
    component: _58f5df57,
    name: "company___en"
  }, {
    path: "/compare",
    component: _25c5e702,
    name: "compare___en"
  }, {
    path: "/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___en"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en"
  }, {
    path: "/cookies",
    component: _119a8c69,
    name: "cookies___en"
  }, {
    path: "/da",
    component: _98173ca8,
    name: "index___da"
  }, {
    path: "/de",
    component: _98173ca8,
    name: "index___de"
  }, {
    path: "/es",
    component: _98173ca8,
    name: "index___es"
  }, {
    path: "/extraversion",
    component: _63a157de,
    name: "extraversion___en"
  }, {
    path: "/faq",
    component: _5fa2ea70,
    name: "faq___en"
  }, {
    path: "/fi",
    component: _98173ca8,
    name: "index___fi"
  }, {
    path: "/fr",
    component: _98173ca8,
    name: "index___fr"
  }, {
    path: "/gdpr",
    component: _c5921996,
    name: "gdpr___en"
  }, {
    path: "/hi",
    component: _98173ca8,
    name: "index___hi"
  }, {
    path: "/id",
    component: _98173ca8,
    name: "index___id"
  }, {
    path: "/is",
    component: _98173ca8,
    name: "index___is"
  }, {
    path: "/it",
    component: _98173ca8,
    name: "index___it"
  }, {
    path: "/ja",
    component: _98173ca8,
    name: "index___ja"
  }, {
    path: "/neuroticism",
    component: _17f60daa,
    name: "neuroticism___en"
  }, {
    path: "/no",
    component: _98173ca8,
    name: "index___no"
  }, {
    path: "/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___en"
  }, {
    path: "/pl",
    component: _98173ca8,
    name: "index___pl"
  }, {
    path: "/privacy",
    component: _5e416e3c,
    name: "privacy___en"
  }, {
    path: "/products",
    component: _9ea4d90c,
    name: "products___en"
  }, {
    path: "/pt",
    component: _98173ca8,
    name: "index___pt"
  }, {
    path: "/result",
    component: _62f66176,
    name: "result___en"
  }, {
    path: "/ru",
    component: _98173ca8,
    name: "index___ru"
  }, {
    path: "/sv",
    component: _98173ca8,
    name: "index___sv"
  }, {
    path: "/terms",
    component: _5df21fe1,
    name: "terms___en"
  }, {
    path: "/test",
    component: _1dc72ea8,
    name: "test___en"
  }, {
    path: "/th",
    component: _98173ca8,
    name: "index___th"
  }, {
    path: "/thank_you",
    component: _58809db8,
    name: "thank_you___en"
  }, {
    path: "/uk",
    component: _98173ca8,
    name: "index___uk"
  }, {
    path: "/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___en"
  }, {
    path: "/zh-Hans",
    component: _98173ca8,
    name: "index___zh-Hans"
  }, {
    path: "/ar/about",
    component: _d786f232,
    name: "about___ar"
  }, {
    path: "/ar/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___ar"
  }, {
    path: "/ar/articles",
    component: _5cb47fd3,
    name: "articles___ar"
  }, {
    path: "/ar/big_five",
    component: _a9ba93b2,
    name: "big_five___ar"
  }, {
    path: "/ar/candidate",
    component: _8644c646,
    name: "candidate___ar"
  }, {
    path: "/ar/companii",
    component: _a4d1909c,
    name: "companii___ar"
  }, {
    path: "/ar/company",
    component: _58f5df57,
    name: "company___ar"
  }, {
    path: "/ar/compare",
    component: _25c5e702,
    name: "compare___ar"
  }, {
    path: "/ar/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___ar"
  }, {
    path: "/ar/contact",
    component: _79b33d7a,
    name: "contact___ar"
  }, {
    path: "/ar/cookies",
    component: _119a8c69,
    name: "cookies___ar"
  }, {
    path: "/ar/extraversion",
    component: _63a157de,
    name: "extraversion___ar"
  }, {
    path: "/ar/faq",
    component: _5fa2ea70,
    name: "faq___ar"
  }, {
    path: "/ar/gdpr",
    component: _c5921996,
    name: "gdpr___ar"
  }, {
    path: "/ar/neuroticism",
    component: _17f60daa,
    name: "neuroticism___ar"
  }, {
    path: "/ar/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___ar"
  }, {
    path: "/ar/privacy",
    component: _5e416e3c,
    name: "privacy___ar"
  }, {
    path: "/ar/products",
    component: _9ea4d90c,
    name: "products___ar"
  }, {
    path: "/ar/result",
    component: _62f66176,
    name: "result___ar"
  }, {
    path: "/ar/terms",
    component: _5df21fe1,
    name: "terms___ar"
  }, {
    path: "/ar/test",
    component: _1dc72ea8,
    name: "test___ar"
  }, {
    path: "/ar/thank_you",
    component: _58809db8,
    name: "thank_you___ar"
  }, {
    path: "/ar/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___ar"
  }, {
    path: "/da/about",
    component: _d786f232,
    name: "about___da"
  }, {
    path: "/da/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___da"
  }, {
    path: "/da/articles",
    component: _5cb47fd3,
    name: "articles___da"
  }, {
    path: "/da/big_five",
    component: _a9ba93b2,
    name: "big_five___da"
  }, {
    path: "/da/candidate",
    component: _8644c646,
    name: "candidate___da"
  }, {
    path: "/da/companii",
    component: _a4d1909c,
    name: "companii___da"
  }, {
    path: "/da/company",
    component: _58f5df57,
    name: "company___da"
  }, {
    path: "/da/compare",
    component: _25c5e702,
    name: "compare___da"
  }, {
    path: "/da/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___da"
  }, {
    path: "/da/contact",
    component: _79b33d7a,
    name: "contact___da"
  }, {
    path: "/da/cookies",
    component: _119a8c69,
    name: "cookies___da"
  }, {
    path: "/da/extraversion",
    component: _63a157de,
    name: "extraversion___da"
  }, {
    path: "/da/faq",
    component: _5fa2ea70,
    name: "faq___da"
  }, {
    path: "/da/gdpr",
    component: _c5921996,
    name: "gdpr___da"
  }, {
    path: "/da/neuroticism",
    component: _17f60daa,
    name: "neuroticism___da"
  }, {
    path: "/da/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___da"
  }, {
    path: "/da/privacy",
    component: _5e416e3c,
    name: "privacy___da"
  }, {
    path: "/da/products",
    component: _9ea4d90c,
    name: "products___da"
  }, {
    path: "/da/result",
    component: _62f66176,
    name: "result___da"
  }, {
    path: "/da/terms",
    component: _5df21fe1,
    name: "terms___da"
  }, {
    path: "/da/test",
    component: _1dc72ea8,
    name: "test___da"
  }, {
    path: "/da/thank_you",
    component: _58809db8,
    name: "thank_you___da"
  }, {
    path: "/da/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___da"
  }, {
    path: "/de/about",
    component: _d786f232,
    name: "about___de"
  }, {
    path: "/de/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___de"
  }, {
    path: "/de/articles",
    component: _5cb47fd3,
    name: "articles___de"
  }, {
    path: "/de/big_five",
    component: _a9ba93b2,
    name: "big_five___de"
  }, {
    path: "/de/candidate",
    component: _8644c646,
    name: "candidate___de"
  }, {
    path: "/de/companii",
    component: _a4d1909c,
    name: "companii___de"
  }, {
    path: "/de/company",
    component: _58f5df57,
    name: "company___de"
  }, {
    path: "/de/compare",
    component: _25c5e702,
    name: "compare___de"
  }, {
    path: "/de/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___de"
  }, {
    path: "/de/contact",
    component: _79b33d7a,
    name: "contact___de"
  }, {
    path: "/de/cookies",
    component: _119a8c69,
    name: "cookies___de"
  }, {
    path: "/de/extraversion",
    component: _63a157de,
    name: "extraversion___de"
  }, {
    path: "/de/faq",
    component: _5fa2ea70,
    name: "faq___de"
  }, {
    path: "/de/gdpr",
    component: _c5921996,
    name: "gdpr___de"
  }, {
    path: "/de/neuroticism",
    component: _17f60daa,
    name: "neuroticism___de"
  }, {
    path: "/de/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___de"
  }, {
    path: "/de/privacy",
    component: _5e416e3c,
    name: "privacy___de"
  }, {
    path: "/de/products",
    component: _9ea4d90c,
    name: "products___de"
  }, {
    path: "/de/result",
    component: _62f66176,
    name: "result___de"
  }, {
    path: "/de/terms",
    component: _5df21fe1,
    name: "terms___de"
  }, {
    path: "/de/test",
    component: _1dc72ea8,
    name: "test___de"
  }, {
    path: "/de/thank_you",
    component: _58809db8,
    name: "thank_you___de"
  }, {
    path: "/de/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___de"
  }, {
    path: "/es/about",
    component: _d786f232,
    name: "about___es"
  }, {
    path: "/es/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___es"
  }, {
    path: "/es/articles",
    component: _5cb47fd3,
    name: "articles___es"
  }, {
    path: "/es/big_five",
    component: _a9ba93b2,
    name: "big_five___es"
  }, {
    path: "/es/candidate",
    component: _8644c646,
    name: "candidate___es"
  }, {
    path: "/es/companii",
    component: _a4d1909c,
    name: "companii___es"
  }, {
    path: "/es/company",
    component: _58f5df57,
    name: "company___es"
  }, {
    path: "/es/compare",
    component: _25c5e702,
    name: "compare___es"
  }, {
    path: "/es/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___es"
  }, {
    path: "/es/contact",
    component: _79b33d7a,
    name: "contact___es"
  }, {
    path: "/es/cookies",
    component: _119a8c69,
    name: "cookies___es"
  }, {
    path: "/es/extraversion",
    component: _63a157de,
    name: "extraversion___es"
  }, {
    path: "/es/faq",
    component: _5fa2ea70,
    name: "faq___es"
  }, {
    path: "/es/gdpr",
    component: _c5921996,
    name: "gdpr___es"
  }, {
    path: "/es/neuroticism",
    component: _17f60daa,
    name: "neuroticism___es"
  }, {
    path: "/es/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___es"
  }, {
    path: "/es/privacy",
    component: _5e416e3c,
    name: "privacy___es"
  }, {
    path: "/es/products",
    component: _9ea4d90c,
    name: "products___es"
  }, {
    path: "/es/result",
    component: _62f66176,
    name: "result___es"
  }, {
    path: "/es/terms",
    component: _5df21fe1,
    name: "terms___es"
  }, {
    path: "/es/test",
    component: _1dc72ea8,
    name: "test___es"
  }, {
    path: "/es/thank_you",
    component: _58809db8,
    name: "thank_you___es"
  }, {
    path: "/es/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___es"
  }, {
    path: "/fi/about",
    component: _d786f232,
    name: "about___fi"
  }, {
    path: "/fi/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___fi"
  }, {
    path: "/fi/articles",
    component: _5cb47fd3,
    name: "articles___fi"
  }, {
    path: "/fi/big_five",
    component: _a9ba93b2,
    name: "big_five___fi"
  }, {
    path: "/fi/candidate",
    component: _8644c646,
    name: "candidate___fi"
  }, {
    path: "/fi/companii",
    component: _a4d1909c,
    name: "companii___fi"
  }, {
    path: "/fi/company",
    component: _58f5df57,
    name: "company___fi"
  }, {
    path: "/fi/compare",
    component: _25c5e702,
    name: "compare___fi"
  }, {
    path: "/fi/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___fi"
  }, {
    path: "/fi/contact",
    component: _79b33d7a,
    name: "contact___fi"
  }, {
    path: "/fi/cookies",
    component: _119a8c69,
    name: "cookies___fi"
  }, {
    path: "/fi/extraversion",
    component: _63a157de,
    name: "extraversion___fi"
  }, {
    path: "/fi/faq",
    component: _5fa2ea70,
    name: "faq___fi"
  }, {
    path: "/fi/gdpr",
    component: _c5921996,
    name: "gdpr___fi"
  }, {
    path: "/fi/neuroticism",
    component: _17f60daa,
    name: "neuroticism___fi"
  }, {
    path: "/fi/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___fi"
  }, {
    path: "/fi/privacy",
    component: _5e416e3c,
    name: "privacy___fi"
  }, {
    path: "/fi/products",
    component: _9ea4d90c,
    name: "products___fi"
  }, {
    path: "/fi/result",
    component: _62f66176,
    name: "result___fi"
  }, {
    path: "/fi/terms",
    component: _5df21fe1,
    name: "terms___fi"
  }, {
    path: "/fi/test",
    component: _1dc72ea8,
    name: "test___fi"
  }, {
    path: "/fi/thank_you",
    component: _58809db8,
    name: "thank_you___fi"
  }, {
    path: "/fi/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___fi"
  }, {
    path: "/fr/about",
    component: _d786f232,
    name: "about___fr"
  }, {
    path: "/fr/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___fr"
  }, {
    path: "/fr/articles",
    component: _5cb47fd3,
    name: "articles___fr"
  }, {
    path: "/fr/big_five",
    component: _a9ba93b2,
    name: "big_five___fr"
  }, {
    path: "/fr/candidate",
    component: _8644c646,
    name: "candidate___fr"
  }, {
    path: "/fr/companii",
    component: _a4d1909c,
    name: "companii___fr"
  }, {
    path: "/fr/company",
    component: _58f5df57,
    name: "company___fr"
  }, {
    path: "/fr/compare",
    component: _25c5e702,
    name: "compare___fr"
  }, {
    path: "/fr/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___fr"
  }, {
    path: "/fr/contact",
    component: _79b33d7a,
    name: "contact___fr"
  }, {
    path: "/fr/cookies",
    component: _119a8c69,
    name: "cookies___fr"
  }, {
    path: "/fr/extraversion",
    component: _63a157de,
    name: "extraversion___fr"
  }, {
    path: "/fr/faq",
    component: _5fa2ea70,
    name: "faq___fr"
  }, {
    path: "/fr/gdpr",
    component: _c5921996,
    name: "gdpr___fr"
  }, {
    path: "/fr/neuroticism",
    component: _17f60daa,
    name: "neuroticism___fr"
  }, {
    path: "/fr/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___fr"
  }, {
    path: "/fr/privacy",
    component: _5e416e3c,
    name: "privacy___fr"
  }, {
    path: "/fr/products",
    component: _9ea4d90c,
    name: "products___fr"
  }, {
    path: "/fr/result",
    component: _62f66176,
    name: "result___fr"
  }, {
    path: "/fr/terms",
    component: _5df21fe1,
    name: "terms___fr"
  }, {
    path: "/fr/test",
    component: _1dc72ea8,
    name: "test___fr"
  }, {
    path: "/fr/thank_you",
    component: _58809db8,
    name: "thank_you___fr"
  }, {
    path: "/fr/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___fr"
  }, {
    path: "/hi/about",
    component: _d786f232,
    name: "about___hi"
  }, {
    path: "/hi/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___hi"
  }, {
    path: "/hi/articles",
    component: _5cb47fd3,
    name: "articles___hi"
  }, {
    path: "/hi/big_five",
    component: _a9ba93b2,
    name: "big_five___hi"
  }, {
    path: "/hi/candidate",
    component: _8644c646,
    name: "candidate___hi"
  }, {
    path: "/hi/companii",
    component: _a4d1909c,
    name: "companii___hi"
  }, {
    path: "/hi/company",
    component: _58f5df57,
    name: "company___hi"
  }, {
    path: "/hi/compare",
    component: _25c5e702,
    name: "compare___hi"
  }, {
    path: "/hi/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___hi"
  }, {
    path: "/hi/contact",
    component: _79b33d7a,
    name: "contact___hi"
  }, {
    path: "/hi/cookies",
    component: _119a8c69,
    name: "cookies___hi"
  }, {
    path: "/hi/extraversion",
    component: _63a157de,
    name: "extraversion___hi"
  }, {
    path: "/hi/faq",
    component: _5fa2ea70,
    name: "faq___hi"
  }, {
    path: "/hi/gdpr",
    component: _c5921996,
    name: "gdpr___hi"
  }, {
    path: "/hi/neuroticism",
    component: _17f60daa,
    name: "neuroticism___hi"
  }, {
    path: "/hi/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___hi"
  }, {
    path: "/hi/privacy",
    component: _5e416e3c,
    name: "privacy___hi"
  }, {
    path: "/hi/products",
    component: _9ea4d90c,
    name: "products___hi"
  }, {
    path: "/hi/result",
    component: _62f66176,
    name: "result___hi"
  }, {
    path: "/hi/terms",
    component: _5df21fe1,
    name: "terms___hi"
  }, {
    path: "/hi/test",
    component: _1dc72ea8,
    name: "test___hi"
  }, {
    path: "/hi/thank_you",
    component: _58809db8,
    name: "thank_you___hi"
  }, {
    path: "/hi/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___hi"
  }, {
    path: "/id/about",
    component: _d786f232,
    name: "about___id"
  }, {
    path: "/id/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___id"
  }, {
    path: "/id/articles",
    component: _5cb47fd3,
    name: "articles___id"
  }, {
    path: "/id/big_five",
    component: _a9ba93b2,
    name: "big_five___id"
  }, {
    path: "/id/candidate",
    component: _8644c646,
    name: "candidate___id"
  }, {
    path: "/id/companii",
    component: _a4d1909c,
    name: "companii___id"
  }, {
    path: "/id/company",
    component: _58f5df57,
    name: "company___id"
  }, {
    path: "/id/compare",
    component: _25c5e702,
    name: "compare___id"
  }, {
    path: "/id/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___id"
  }, {
    path: "/id/contact",
    component: _79b33d7a,
    name: "contact___id"
  }, {
    path: "/id/cookies",
    component: _119a8c69,
    name: "cookies___id"
  }, {
    path: "/id/extraversion",
    component: _63a157de,
    name: "extraversion___id"
  }, {
    path: "/id/faq",
    component: _5fa2ea70,
    name: "faq___id"
  }, {
    path: "/id/gdpr",
    component: _c5921996,
    name: "gdpr___id"
  }, {
    path: "/id/neuroticism",
    component: _17f60daa,
    name: "neuroticism___id"
  }, {
    path: "/id/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___id"
  }, {
    path: "/id/privacy",
    component: _5e416e3c,
    name: "privacy___id"
  }, {
    path: "/id/products",
    component: _9ea4d90c,
    name: "products___id"
  }, {
    path: "/id/result",
    component: _62f66176,
    name: "result___id"
  }, {
    path: "/id/terms",
    component: _5df21fe1,
    name: "terms___id"
  }, {
    path: "/id/test",
    component: _1dc72ea8,
    name: "test___id"
  }, {
    path: "/id/thank_you",
    component: _58809db8,
    name: "thank_you___id"
  }, {
    path: "/id/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___id"
  }, {
    path: "/is/about",
    component: _d786f232,
    name: "about___is"
  }, {
    path: "/is/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___is"
  }, {
    path: "/is/articles",
    component: _5cb47fd3,
    name: "articles___is"
  }, {
    path: "/is/big_five",
    component: _a9ba93b2,
    name: "big_five___is"
  }, {
    path: "/is/candidate",
    component: _8644c646,
    name: "candidate___is"
  }, {
    path: "/is/companii",
    component: _a4d1909c,
    name: "companii___is"
  }, {
    path: "/is/company",
    component: _58f5df57,
    name: "company___is"
  }, {
    path: "/is/compare",
    component: _25c5e702,
    name: "compare___is"
  }, {
    path: "/is/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___is"
  }, {
    path: "/is/contact",
    component: _79b33d7a,
    name: "contact___is"
  }, {
    path: "/is/cookies",
    component: _119a8c69,
    name: "cookies___is"
  }, {
    path: "/is/extraversion",
    component: _63a157de,
    name: "extraversion___is"
  }, {
    path: "/is/faq",
    component: _5fa2ea70,
    name: "faq___is"
  }, {
    path: "/is/gdpr",
    component: _c5921996,
    name: "gdpr___is"
  }, {
    path: "/is/neuroticism",
    component: _17f60daa,
    name: "neuroticism___is"
  }, {
    path: "/is/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___is"
  }, {
    path: "/is/privacy",
    component: _5e416e3c,
    name: "privacy___is"
  }, {
    path: "/is/products",
    component: _9ea4d90c,
    name: "products___is"
  }, {
    path: "/is/result",
    component: _62f66176,
    name: "result___is"
  }, {
    path: "/is/terms",
    component: _5df21fe1,
    name: "terms___is"
  }, {
    path: "/is/test",
    component: _1dc72ea8,
    name: "test___is"
  }, {
    path: "/is/thank_you",
    component: _58809db8,
    name: "thank_you___is"
  }, {
    path: "/is/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___is"
  }, {
    path: "/it/about",
    component: _d786f232,
    name: "about___it"
  }, {
    path: "/it/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___it"
  }, {
    path: "/it/articles",
    component: _5cb47fd3,
    name: "articles___it"
  }, {
    path: "/it/big_five",
    component: _a9ba93b2,
    name: "big_five___it"
  }, {
    path: "/it/candidate",
    component: _8644c646,
    name: "candidate___it"
  }, {
    path: "/it/companii",
    component: _a4d1909c,
    name: "companii___it"
  }, {
    path: "/it/company",
    component: _58f5df57,
    name: "company___it"
  }, {
    path: "/it/compare",
    component: _25c5e702,
    name: "compare___it"
  }, {
    path: "/it/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___it"
  }, {
    path: "/it/contact",
    component: _79b33d7a,
    name: "contact___it"
  }, {
    path: "/it/cookies",
    component: _119a8c69,
    name: "cookies___it"
  }, {
    path: "/it/extraversion",
    component: _63a157de,
    name: "extraversion___it"
  }, {
    path: "/it/faq",
    component: _5fa2ea70,
    name: "faq___it"
  }, {
    path: "/it/gdpr",
    component: _c5921996,
    name: "gdpr___it"
  }, {
    path: "/it/neuroticism",
    component: _17f60daa,
    name: "neuroticism___it"
  }, {
    path: "/it/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___it"
  }, {
    path: "/it/privacy",
    component: _5e416e3c,
    name: "privacy___it"
  }, {
    path: "/it/products",
    component: _9ea4d90c,
    name: "products___it"
  }, {
    path: "/it/result",
    component: _62f66176,
    name: "result___it"
  }, {
    path: "/it/terms",
    component: _5df21fe1,
    name: "terms___it"
  }, {
    path: "/it/test",
    component: _1dc72ea8,
    name: "test___it"
  }, {
    path: "/it/thank_you",
    component: _58809db8,
    name: "thank_you___it"
  }, {
    path: "/it/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___it"
  }, {
    path: "/ja/about",
    component: _d786f232,
    name: "about___ja"
  }, {
    path: "/ja/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___ja"
  }, {
    path: "/ja/articles",
    component: _5cb47fd3,
    name: "articles___ja"
  }, {
    path: "/ja/big_five",
    component: _a9ba93b2,
    name: "big_five___ja"
  }, {
    path: "/ja/candidate",
    component: _8644c646,
    name: "candidate___ja"
  }, {
    path: "/ja/companii",
    component: _a4d1909c,
    name: "companii___ja"
  }, {
    path: "/ja/company",
    component: _58f5df57,
    name: "company___ja"
  }, {
    path: "/ja/compare",
    component: _25c5e702,
    name: "compare___ja"
  }, {
    path: "/ja/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___ja"
  }, {
    path: "/ja/contact",
    component: _79b33d7a,
    name: "contact___ja"
  }, {
    path: "/ja/cookies",
    component: _119a8c69,
    name: "cookies___ja"
  }, {
    path: "/ja/extraversion",
    component: _63a157de,
    name: "extraversion___ja"
  }, {
    path: "/ja/faq",
    component: _5fa2ea70,
    name: "faq___ja"
  }, {
    path: "/ja/gdpr",
    component: _c5921996,
    name: "gdpr___ja"
  }, {
    path: "/ja/neuroticism",
    component: _17f60daa,
    name: "neuroticism___ja"
  }, {
    path: "/ja/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___ja"
  }, {
    path: "/ja/privacy",
    component: _5e416e3c,
    name: "privacy___ja"
  }, {
    path: "/ja/products",
    component: _9ea4d90c,
    name: "products___ja"
  }, {
    path: "/ja/result",
    component: _62f66176,
    name: "result___ja"
  }, {
    path: "/ja/terms",
    component: _5df21fe1,
    name: "terms___ja"
  }, {
    path: "/ja/test",
    component: _1dc72ea8,
    name: "test___ja"
  }, {
    path: "/ja/thank_you",
    component: _58809db8,
    name: "thank_you___ja"
  }, {
    path: "/ja/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___ja"
  }, {
    path: "/no/about",
    component: _d786f232,
    name: "about___no"
  }, {
    path: "/no/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___no"
  }, {
    path: "/no/articles",
    component: _5cb47fd3,
    name: "articles___no"
  }, {
    path: "/no/big_five",
    component: _a9ba93b2,
    name: "big_five___no"
  }, {
    path: "/no/candidate",
    component: _8644c646,
    name: "candidate___no"
  }, {
    path: "/no/companii",
    component: _a4d1909c,
    name: "companii___no"
  }, {
    path: "/no/company",
    component: _58f5df57,
    name: "company___no"
  }, {
    path: "/no/compare",
    component: _25c5e702,
    name: "compare___no"
  }, {
    path: "/no/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___no"
  }, {
    path: "/no/contact",
    component: _79b33d7a,
    name: "contact___no"
  }, {
    path: "/no/cookies",
    component: _119a8c69,
    name: "cookies___no"
  }, {
    path: "/no/extraversion",
    component: _63a157de,
    name: "extraversion___no"
  }, {
    path: "/no/faq",
    component: _5fa2ea70,
    name: "faq___no"
  }, {
    path: "/no/gdpr",
    component: _c5921996,
    name: "gdpr___no"
  }, {
    path: "/no/neuroticism",
    component: _17f60daa,
    name: "neuroticism___no"
  }, {
    path: "/no/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___no"
  }, {
    path: "/no/privacy",
    component: _5e416e3c,
    name: "privacy___no"
  }, {
    path: "/no/products",
    component: _9ea4d90c,
    name: "products___no"
  }, {
    path: "/no/result",
    component: _62f66176,
    name: "result___no"
  }, {
    path: "/no/terms",
    component: _5df21fe1,
    name: "terms___no"
  }, {
    path: "/no/test",
    component: _1dc72ea8,
    name: "test___no"
  }, {
    path: "/no/thank_you",
    component: _58809db8,
    name: "thank_you___no"
  }, {
    path: "/no/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___no"
  }, {
    path: "/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___en"
  }, {
    path: "/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___en"
  }, {
    path: "/pl/about",
    component: _d786f232,
    name: "about___pl"
  }, {
    path: "/pl/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___pl"
  }, {
    path: "/pl/articles",
    component: _5cb47fd3,
    name: "articles___pl"
  }, {
    path: "/pl/big_five",
    component: _a9ba93b2,
    name: "big_five___pl"
  }, {
    path: "/pl/candidate",
    component: _8644c646,
    name: "candidate___pl"
  }, {
    path: "/pl/companii",
    component: _a4d1909c,
    name: "companii___pl"
  }, {
    path: "/pl/company",
    component: _58f5df57,
    name: "company___pl"
  }, {
    path: "/pl/compare",
    component: _25c5e702,
    name: "compare___pl"
  }, {
    path: "/pl/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___pl"
  }, {
    path: "/pl/contact",
    component: _79b33d7a,
    name: "contact___pl"
  }, {
    path: "/pl/cookies",
    component: _119a8c69,
    name: "cookies___pl"
  }, {
    path: "/pl/extraversion",
    component: _63a157de,
    name: "extraversion___pl"
  }, {
    path: "/pl/faq",
    component: _5fa2ea70,
    name: "faq___pl"
  }, {
    path: "/pl/gdpr",
    component: _c5921996,
    name: "gdpr___pl"
  }, {
    path: "/pl/neuroticism",
    component: _17f60daa,
    name: "neuroticism___pl"
  }, {
    path: "/pl/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___pl"
  }, {
    path: "/pl/privacy",
    component: _5e416e3c,
    name: "privacy___pl"
  }, {
    path: "/pl/products",
    component: _9ea4d90c,
    name: "products___pl"
  }, {
    path: "/pl/result",
    component: _62f66176,
    name: "result___pl"
  }, {
    path: "/pl/terms",
    component: _5df21fe1,
    name: "terms___pl"
  }, {
    path: "/pl/test",
    component: _1dc72ea8,
    name: "test___pl"
  }, {
    path: "/pl/thank_you",
    component: _58809db8,
    name: "thank_you___pl"
  }, {
    path: "/pl/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___pl"
  }, {
    path: "/pt/about",
    component: _d786f232,
    name: "about___pt"
  }, {
    path: "/pt/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___pt"
  }, {
    path: "/pt/articles",
    component: _5cb47fd3,
    name: "articles___pt"
  }, {
    path: "/pt/big_five",
    component: _a9ba93b2,
    name: "big_five___pt"
  }, {
    path: "/pt/candidate",
    component: _8644c646,
    name: "candidate___pt"
  }, {
    path: "/pt/companii",
    component: _a4d1909c,
    name: "companii___pt"
  }, {
    path: "/pt/company",
    component: _58f5df57,
    name: "company___pt"
  }, {
    path: "/pt/compare",
    component: _25c5e702,
    name: "compare___pt"
  }, {
    path: "/pt/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___pt"
  }, {
    path: "/pt/contact",
    component: _79b33d7a,
    name: "contact___pt"
  }, {
    path: "/pt/cookies",
    component: _119a8c69,
    name: "cookies___pt"
  }, {
    path: "/pt/extraversion",
    component: _63a157de,
    name: "extraversion___pt"
  }, {
    path: "/pt/faq",
    component: _5fa2ea70,
    name: "faq___pt"
  }, {
    path: "/pt/gdpr",
    component: _c5921996,
    name: "gdpr___pt"
  }, {
    path: "/pt/neuroticism",
    component: _17f60daa,
    name: "neuroticism___pt"
  }, {
    path: "/pt/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___pt"
  }, {
    path: "/pt/privacy",
    component: _5e416e3c,
    name: "privacy___pt"
  }, {
    path: "/pt/products",
    component: _9ea4d90c,
    name: "products___pt"
  }, {
    path: "/pt/result",
    component: _62f66176,
    name: "result___pt"
  }, {
    path: "/pt/terms",
    component: _5df21fe1,
    name: "terms___pt"
  }, {
    path: "/pt/test",
    component: _1dc72ea8,
    name: "test___pt"
  }, {
    path: "/pt/thank_you",
    component: _58809db8,
    name: "thank_you___pt"
  }, {
    path: "/pt/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___pt"
  }, {
    path: "/ru/about",
    component: _d786f232,
    name: "about___ru"
  }, {
    path: "/ru/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___ru"
  }, {
    path: "/ru/articles",
    component: _5cb47fd3,
    name: "articles___ru"
  }, {
    path: "/ru/big_five",
    component: _a9ba93b2,
    name: "big_five___ru"
  }, {
    path: "/ru/candidate",
    component: _8644c646,
    name: "candidate___ru"
  }, {
    path: "/ru/companii",
    component: _a4d1909c,
    name: "companii___ru"
  }, {
    path: "/ru/company",
    component: _58f5df57,
    name: "company___ru"
  }, {
    path: "/ru/compare",
    component: _25c5e702,
    name: "compare___ru"
  }, {
    path: "/ru/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___ru"
  }, {
    path: "/ru/contact",
    component: _79b33d7a,
    name: "contact___ru"
  }, {
    path: "/ru/cookies",
    component: _119a8c69,
    name: "cookies___ru"
  }, {
    path: "/ru/extraversion",
    component: _63a157de,
    name: "extraversion___ru"
  }, {
    path: "/ru/faq",
    component: _5fa2ea70,
    name: "faq___ru"
  }, {
    path: "/ru/gdpr",
    component: _c5921996,
    name: "gdpr___ru"
  }, {
    path: "/ru/neuroticism",
    component: _17f60daa,
    name: "neuroticism___ru"
  }, {
    path: "/ru/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___ru"
  }, {
    path: "/ru/privacy",
    component: _5e416e3c,
    name: "privacy___ru"
  }, {
    path: "/ru/products",
    component: _9ea4d90c,
    name: "products___ru"
  }, {
    path: "/ru/result",
    component: _62f66176,
    name: "result___ru"
  }, {
    path: "/ru/terms",
    component: _5df21fe1,
    name: "terms___ru"
  }, {
    path: "/ru/test",
    component: _1dc72ea8,
    name: "test___ru"
  }, {
    path: "/ru/thank_you",
    component: _58809db8,
    name: "thank_you___ru"
  }, {
    path: "/ru/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___ru"
  }, {
    path: "/sv/about",
    component: _d786f232,
    name: "about___sv"
  }, {
    path: "/sv/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___sv"
  }, {
    path: "/sv/articles",
    component: _5cb47fd3,
    name: "articles___sv"
  }, {
    path: "/sv/big_five",
    component: _a9ba93b2,
    name: "big_five___sv"
  }, {
    path: "/sv/candidate",
    component: _8644c646,
    name: "candidate___sv"
  }, {
    path: "/sv/companii",
    component: _a4d1909c,
    name: "companii___sv"
  }, {
    path: "/sv/company",
    component: _58f5df57,
    name: "company___sv"
  }, {
    path: "/sv/compare",
    component: _25c5e702,
    name: "compare___sv"
  }, {
    path: "/sv/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___sv"
  }, {
    path: "/sv/contact",
    component: _79b33d7a,
    name: "contact___sv"
  }, {
    path: "/sv/cookies",
    component: _119a8c69,
    name: "cookies___sv"
  }, {
    path: "/sv/extraversion",
    component: _63a157de,
    name: "extraversion___sv"
  }, {
    path: "/sv/faq",
    component: _5fa2ea70,
    name: "faq___sv"
  }, {
    path: "/sv/gdpr",
    component: _c5921996,
    name: "gdpr___sv"
  }, {
    path: "/sv/neuroticism",
    component: _17f60daa,
    name: "neuroticism___sv"
  }, {
    path: "/sv/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___sv"
  }, {
    path: "/sv/privacy",
    component: _5e416e3c,
    name: "privacy___sv"
  }, {
    path: "/sv/products",
    component: _9ea4d90c,
    name: "products___sv"
  }, {
    path: "/sv/result",
    component: _62f66176,
    name: "result___sv"
  }, {
    path: "/sv/terms",
    component: _5df21fe1,
    name: "terms___sv"
  }, {
    path: "/sv/test",
    component: _1dc72ea8,
    name: "test___sv"
  }, {
    path: "/sv/thank_you",
    component: _58809db8,
    name: "thank_you___sv"
  }, {
    path: "/sv/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___sv"
  }, {
    path: "/th/about",
    component: _d786f232,
    name: "about___th"
  }, {
    path: "/th/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___th"
  }, {
    path: "/th/articles",
    component: _5cb47fd3,
    name: "articles___th"
  }, {
    path: "/th/big_five",
    component: _a9ba93b2,
    name: "big_five___th"
  }, {
    path: "/th/candidate",
    component: _8644c646,
    name: "candidate___th"
  }, {
    path: "/th/companii",
    component: _a4d1909c,
    name: "companii___th"
  }, {
    path: "/th/company",
    component: _58f5df57,
    name: "company___th"
  }, {
    path: "/th/compare",
    component: _25c5e702,
    name: "compare___th"
  }, {
    path: "/th/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___th"
  }, {
    path: "/th/contact",
    component: _79b33d7a,
    name: "contact___th"
  }, {
    path: "/th/cookies",
    component: _119a8c69,
    name: "cookies___th"
  }, {
    path: "/th/extraversion",
    component: _63a157de,
    name: "extraversion___th"
  }, {
    path: "/th/faq",
    component: _5fa2ea70,
    name: "faq___th"
  }, {
    path: "/th/gdpr",
    component: _c5921996,
    name: "gdpr___th"
  }, {
    path: "/th/neuroticism",
    component: _17f60daa,
    name: "neuroticism___th"
  }, {
    path: "/th/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___th"
  }, {
    path: "/th/privacy",
    component: _5e416e3c,
    name: "privacy___th"
  }, {
    path: "/th/products",
    component: _9ea4d90c,
    name: "products___th"
  }, {
    path: "/th/result",
    component: _62f66176,
    name: "result___th"
  }, {
    path: "/th/terms",
    component: _5df21fe1,
    name: "terms___th"
  }, {
    path: "/th/test",
    component: _1dc72ea8,
    name: "test___th"
  }, {
    path: "/th/thank_you",
    component: _58809db8,
    name: "thank_you___th"
  }, {
    path: "/th/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___th"
  }, {
    path: "/uk/about",
    component: _d786f232,
    name: "about___uk"
  }, {
    path: "/uk/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___uk"
  }, {
    path: "/uk/articles",
    component: _5cb47fd3,
    name: "articles___uk"
  }, {
    path: "/uk/big_five",
    component: _a9ba93b2,
    name: "big_five___uk"
  }, {
    path: "/uk/candidate",
    component: _8644c646,
    name: "candidate___uk"
  }, {
    path: "/uk/companii",
    component: _a4d1909c,
    name: "companii___uk"
  }, {
    path: "/uk/company",
    component: _58f5df57,
    name: "company___uk"
  }, {
    path: "/uk/compare",
    component: _25c5e702,
    name: "compare___uk"
  }, {
    path: "/uk/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___uk"
  }, {
    path: "/uk/contact",
    component: _79b33d7a,
    name: "contact___uk"
  }, {
    path: "/uk/cookies",
    component: _119a8c69,
    name: "cookies___uk"
  }, {
    path: "/uk/extraversion",
    component: _63a157de,
    name: "extraversion___uk"
  }, {
    path: "/uk/faq",
    component: _5fa2ea70,
    name: "faq___uk"
  }, {
    path: "/uk/gdpr",
    component: _c5921996,
    name: "gdpr___uk"
  }, {
    path: "/uk/neuroticism",
    component: _17f60daa,
    name: "neuroticism___uk"
  }, {
    path: "/uk/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___uk"
  }, {
    path: "/uk/privacy",
    component: _5e416e3c,
    name: "privacy___uk"
  }, {
    path: "/uk/products",
    component: _9ea4d90c,
    name: "products___uk"
  }, {
    path: "/uk/result",
    component: _62f66176,
    name: "result___uk"
  }, {
    path: "/uk/terms",
    component: _5df21fe1,
    name: "terms___uk"
  }, {
    path: "/uk/test",
    component: _1dc72ea8,
    name: "test___uk"
  }, {
    path: "/uk/thank_you",
    component: _58809db8,
    name: "thank_you___uk"
  }, {
    path: "/uk/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___uk"
  }, {
    path: "/zh-Hans/about",
    component: _d786f232,
    name: "about___zh-Hans"
  }, {
    path: "/zh-Hans/agreeableness",
    component: _2e2882b7,
    name: "agreeableness___zh-Hans"
  }, {
    path: "/zh-Hans/articles",
    component: _5cb47fd3,
    name: "articles___zh-Hans"
  }, {
    path: "/zh-Hans/big_five",
    component: _a9ba93b2,
    name: "big_five___zh-Hans"
  }, {
    path: "/zh-Hans/candidate",
    component: _8644c646,
    name: "candidate___zh-Hans"
  }, {
    path: "/zh-Hans/companii",
    component: _a4d1909c,
    name: "companii___zh-Hans"
  }, {
    path: "/zh-Hans/company",
    component: _58f5df57,
    name: "company___zh-Hans"
  }, {
    path: "/zh-Hans/compare",
    component: _25c5e702,
    name: "compare___zh-Hans"
  }, {
    path: "/zh-Hans/conscientiousness",
    component: _276f6029,
    name: "conscientiousness___zh-Hans"
  }, {
    path: "/zh-Hans/contact",
    component: _79b33d7a,
    name: "contact___zh-Hans"
  }, {
    path: "/zh-Hans/cookies",
    component: _119a8c69,
    name: "cookies___zh-Hans"
  }, {
    path: "/zh-Hans/extraversion",
    component: _63a157de,
    name: "extraversion___zh-Hans"
  }, {
    path: "/zh-Hans/faq",
    component: _5fa2ea70,
    name: "faq___zh-Hans"
  }, {
    path: "/zh-Hans/gdpr",
    component: _c5921996,
    name: "gdpr___zh-Hans"
  }, {
    path: "/zh-Hans/neuroticism",
    component: _17f60daa,
    name: "neuroticism___zh-Hans"
  }, {
    path: "/zh-Hans/openness_to_experience",
    component: _74168d26,
    name: "openness_to_experience___zh-Hans"
  }, {
    path: "/zh-Hans/privacy",
    component: _5e416e3c,
    name: "privacy___zh-Hans"
  }, {
    path: "/zh-Hans/products",
    component: _9ea4d90c,
    name: "products___zh-Hans"
  }, {
    path: "/zh-Hans/result",
    component: _62f66176,
    name: "result___zh-Hans"
  }, {
    path: "/zh-Hans/terms",
    component: _5df21fe1,
    name: "terms___zh-Hans"
  }, {
    path: "/zh-Hans/test",
    component: _1dc72ea8,
    name: "test___zh-Hans"
  }, {
    path: "/zh-Hans/thank_you",
    component: _58809db8,
    name: "thank_you___zh-Hans"
  }, {
    path: "/zh-Hans/unsubscribe",
    component: _99e35aaa,
    name: "unsubscribe___zh-Hans"
  }, {
    path: "/ar/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___ar"
  }, {
    path: "/ar/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___ar"
  }, {
    path: "/da/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___da"
  }, {
    path: "/da/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___da"
  }, {
    path: "/de/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___de"
  }, {
    path: "/de/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___de"
  }, {
    path: "/es/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___es"
  }, {
    path: "/es/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___es"
  }, {
    path: "/fi/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___fi"
  }, {
    path: "/fi/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___fi"
  }, {
    path: "/fr/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___fr"
  }, {
    path: "/fr/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___fr"
  }, {
    path: "/hi/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___hi"
  }, {
    path: "/hi/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___hi"
  }, {
    path: "/id/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___id"
  }, {
    path: "/id/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___id"
  }, {
    path: "/is/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___is"
  }, {
    path: "/is/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___is"
  }, {
    path: "/it/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___it"
  }, {
    path: "/it/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___it"
  }, {
    path: "/ja/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___ja"
  }, {
    path: "/ja/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___ja"
  }, {
    path: "/no/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___no"
  }, {
    path: "/no/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___no"
  }, {
    path: "/pl/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___pl"
  }, {
    path: "/pl/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___pl"
  }, {
    path: "/pt/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___pt"
  }, {
    path: "/pt/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___pt"
  }, {
    path: "/ru/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___ru"
  }, {
    path: "/ru/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___ru"
  }, {
    path: "/sv/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___sv"
  }, {
    path: "/sv/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___sv"
  }, {
    path: "/th/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___th"
  }, {
    path: "/th/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___th"
  }, {
    path: "/uk/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___uk"
  }, {
    path: "/uk/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___uk"
  }, {
    path: "/zh-Hans/parteneri/ang",
    component: _ca0c94b6,
    name: "parteneri-ang___zh-Hans"
  }, {
    path: "/zh-Hans/parteneri/hpdi",
    component: _0331dbd2,
    name: "parteneri-hpdi___zh-Hans"
  }, {
    path: "/ar/compare/:id",
    component: _1f747c2c,
    name: "compare-id___ar"
  }, {
    path: "/ar/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___ar"
  }, {
    path: "/ar/result/:id",
    component: _687a495e,
    name: "result-id___ar"
  }, {
    path: "/da/compare/:id",
    component: _1f747c2c,
    name: "compare-id___da"
  }, {
    path: "/da/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___da"
  }, {
    path: "/da/result/:id",
    component: _687a495e,
    name: "result-id___da"
  }, {
    path: "/de/compare/:id",
    component: _1f747c2c,
    name: "compare-id___de"
  }, {
    path: "/de/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___de"
  }, {
    path: "/de/result/:id",
    component: _687a495e,
    name: "result-id___de"
  }, {
    path: "/es/compare/:id",
    component: _1f747c2c,
    name: "compare-id___es"
  }, {
    path: "/es/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___es"
  }, {
    path: "/es/result/:id",
    component: _687a495e,
    name: "result-id___es"
  }, {
    path: "/fi/compare/:id",
    component: _1f747c2c,
    name: "compare-id___fi"
  }, {
    path: "/fi/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___fi"
  }, {
    path: "/fi/result/:id",
    component: _687a495e,
    name: "result-id___fi"
  }, {
    path: "/fr/compare/:id",
    component: _1f747c2c,
    name: "compare-id___fr"
  }, {
    path: "/fr/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___fr"
  }, {
    path: "/fr/result/:id",
    component: _687a495e,
    name: "result-id___fr"
  }, {
    path: "/hi/compare/:id",
    component: _1f747c2c,
    name: "compare-id___hi"
  }, {
    path: "/hi/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___hi"
  }, {
    path: "/hi/result/:id",
    component: _687a495e,
    name: "result-id___hi"
  }, {
    path: "/id/compare/:id",
    component: _1f747c2c,
    name: "compare-id___id"
  }, {
    path: "/id/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___id"
  }, {
    path: "/id/result/:id",
    component: _687a495e,
    name: "result-id___id"
  }, {
    path: "/is/compare/:id",
    component: _1f747c2c,
    name: "compare-id___is"
  }, {
    path: "/is/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___is"
  }, {
    path: "/is/result/:id",
    component: _687a495e,
    name: "result-id___is"
  }, {
    path: "/it/compare/:id",
    component: _1f747c2c,
    name: "compare-id___it"
  }, {
    path: "/it/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___it"
  }, {
    path: "/it/result/:id",
    component: _687a495e,
    name: "result-id___it"
  }, {
    path: "/ja/compare/:id",
    component: _1f747c2c,
    name: "compare-id___ja"
  }, {
    path: "/ja/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___ja"
  }, {
    path: "/ja/result/:id",
    component: _687a495e,
    name: "result-id___ja"
  }, {
    path: "/no/compare/:id",
    component: _1f747c2c,
    name: "compare-id___no"
  }, {
    path: "/no/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___no"
  }, {
    path: "/no/result/:id",
    component: _687a495e,
    name: "result-id___no"
  }, {
    path: "/pl/compare/:id",
    component: _1f747c2c,
    name: "compare-id___pl"
  }, {
    path: "/pl/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___pl"
  }, {
    path: "/pl/result/:id",
    component: _687a495e,
    name: "result-id___pl"
  }, {
    path: "/pt/compare/:id",
    component: _1f747c2c,
    name: "compare-id___pt"
  }, {
    path: "/pt/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___pt"
  }, {
    path: "/pt/result/:id",
    component: _687a495e,
    name: "result-id___pt"
  }, {
    path: "/ru/compare/:id",
    component: _1f747c2c,
    name: "compare-id___ru"
  }, {
    path: "/ru/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___ru"
  }, {
    path: "/ru/result/:id",
    component: _687a495e,
    name: "result-id___ru"
  }, {
    path: "/sv/compare/:id",
    component: _1f747c2c,
    name: "compare-id___sv"
  }, {
    path: "/sv/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___sv"
  }, {
    path: "/sv/result/:id",
    component: _687a495e,
    name: "result-id___sv"
  }, {
    path: "/th/compare/:id",
    component: _1f747c2c,
    name: "compare-id___th"
  }, {
    path: "/th/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___th"
  }, {
    path: "/th/result/:id",
    component: _687a495e,
    name: "result-id___th"
  }, {
    path: "/uk/compare/:id",
    component: _1f747c2c,
    name: "compare-id___uk"
  }, {
    path: "/uk/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___uk"
  }, {
    path: "/uk/result/:id",
    component: _687a495e,
    name: "result-id___uk"
  }, {
    path: "/zh-Hans/compare/:id",
    component: _1f747c2c,
    name: "compare-id___zh-Hans"
  }, {
    path: "/zh-Hans/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___zh-Hans"
  }, {
    path: "/zh-Hans/result/:id",
    component: _687a495e,
    name: "result-id___zh-Hans"
  }, {
    path: "/compare/:id",
    component: _1f747c2c,
    name: "compare-id___en"
  }, {
    path: "/parteneri/:partner?",
    component: _52eda964,
    name: "parteneri-partner___en"
  }, {
    path: "/result/:id",
    component: _687a495e,
    name: "result-id___en"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
