import { render, staticRenderFns } from "./Toolbar.vue?vue&type=template&id=83ce4e88&scoped=true&"
import script from "./Toolbar.vue?vue&type=script&lang=js&"
export * from "./Toolbar.vue?vue&type=script&lang=js&"
import style0 from "./Toolbar.vue?vue&type=style&index=0&id=83ce4e88&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83ce4e88",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitcher: require('/vercel/path0/components/LanguageSwitcher.vue').default})
